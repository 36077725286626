
.MuiDropzonePreviewList-removeButton{


    opacity: 1 !important;
   
     position: relative !important; 
    transition: .5s ease;
    margin-top: 13px !important;
    margin-right: 15px !important;
}

.MuiGrid-spacing-xs-8 > .MuiGrid-item {
    margin-top: 35 !important;
    padding: 32px;
}

.MuiButton-containedPrimary {
background-color: #138de8 !important;
color:  white;
}

.abc {
    color: gray;
    font-size: 0.6cm;
    font: "Roboto" ;
    
}

/* .MuiSvgIcon-colorPrimary{
    color: #138de8 !important;
}

.MuiSvgIcon-colorSecondary{
    color: #FF9017 !important;
} */


div .MuiSvgIcon-colorPrimary .MuiSvgIcon-colorSecondary{
    color: #138DE8 !important;
}