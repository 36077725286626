.MuiDataGrid-row:nth-child(even) {
  background-color: #c3c3c35e
}

  .member {
    margin-bottom: 0px !important;
    margin-top: 20px !important;
   }

   .memberDetails_header {
    padding:0px 18px !important;
  }

  .memberDetails_body {
    padding: 0px 10px 20px 10px !important;
  }

  .memberDetails_card {
    padding: 0px 10px 0px 10px !important;
  }

  .memberDetails_container {
    padding: 10px 0px 10px 0px !important;
  }

.header__buttons, .action__icons {
  float: right;
}

.edit {
  margin: 10px;
}

.member_details_modal-overlay.modal > .close {
  right: -13px !important;
  top: -12px;
}

.my-popover {
  position: relative !important;
  left: -200px; 
  background-color: white;
}

.MuiDataGrid-root {
    flex: 1;
    color: rgba(0, 0, 0, 0.87);
    border: 1px solid rgba(224, 224, 224, 1);
    height: 100%;
    display: flex;
    outline: none;
    position: relative;
    font-size: 0.875rem;
    box-sizing: border-box;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    border-radius: 4px;
    flex-direction: column;
    letter-spacing: 0.01071em;
}


.MuiDataGrid-colCell {
    padding: 12px 8px!important;
    position: relative;
    font-size: 1em;
    border-top: 1px solid #DDD;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.42857143;
    border-bottom: none;
    vertical-align: middle;
  }

.MuiDataGrid-colCellTitle {
  padding: 12px 8px!important;
  position: relative;
  font-size: 1em;
  border-top: 1px solid #DDD;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.42857143;
  border-bottom: none;
  vertical-align: middle;
}

.card-header-body {
  background-color: rgba(238, 238, 238, 1);
  box-shadow: none !important;
  border-radius: 0px !important;
}


.MuiDataGrid-overlay{  color: '#138De8';
}