.AddButton {
    background-color: #ff9017 !important;
    z-index:  1;
    float: right;
    /* top: -20px; */

}

.MuiDialog-paperFullWidth {
    max-width: max-content !important;

}

.MuiDialog-paperWidthSm {
    max-width: max-content !important;

}

.transaction-cell-div {
    padding: 10px 10px 0px 10px
}

.header-cell-green-p {
    font-weight: 400;
    color: #3FA340;
    padding: 18px 0px;
    font-size: x-large;
}

.header-cell-red-p {
    font-weight: 400 ;
    color: #ff4500;
    padding: 18px 0px;
    font-size: x-large;
}

.plus-or-minus-icon-red {
    height: 60px;
    width: 60px;
    color: #3FA340;
}




.MuiStepIcon-root.MuiStepIcon-completed {
    color: #138de8 !important;
}

.MuiStepIcon-root.MuiStepIcon-active {
    color: #138de8 !important;

}

/* .MuiButton-textPrimary {
      background-color: #138DE8  !important;
      color: floralwhite !important;
       margin-right: 5px !important;
    
    } 

    .MuiButton-label {
        color: floralwhite !important;
        width: 100%;
       
        display: inherit;
        align-items: inherit;
        justify-content: inherit;
    }
  */