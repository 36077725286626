.sub-header-light {
    font-weight: 400;
    color: #A3A3A3;

}

.content-a1 {
    color: #000;
    font-weight: 400
}

.MuiDropzonePreviewList-image {
    width: 100% !important;
    height: 100% !important;
}